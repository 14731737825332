<template>
  <nav class="nav-title">
    <div class="clear">
      <div @click="navBackIncident" class="fl">{{ title }}</div>
      <div class="fl" v-if="conton">> {{ conton }}</div>
      <div class="fl" v-if="text">> {{ text }}</div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'NavTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    conton: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    isBack: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    navBackIncident() {
      if (this.isBack) {
        this.$router.back();
      } else {
        this.$emit('navBackIncident', {});
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.nav-title {
  margin-top: 20px;
  padding-bottom: 40px;
  .fontSize(@TEXT-SIZE-12);
  color: @TEXT-COLOR-888888;
  .fl {
    cursor: pointer;
  }
}
</style>