export default {
  data() {
    return {
      loading: true,
      list: [],
      pageSize: 10,
      pageNum: 1,
      currentTotal: 0
    };
  },
  created() {
    this.initList();
  },
  watch: {
    page: 'loadData'
  },
  methods: {
    // timeFormatter,
    /**
     * 获取请求参数，默认只传递index(页码)，limit(每页条数)，可以由调用方法传递指定对象合并（或者覆盖）原参数
     * @param params
     * @returns {any}
     */
    getParams(params) {
      return {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...params
      };
    },
    /**
     * 初始化列表
     */
    initList() {
      this.pageNum = 1;
      this.list = [];
      this.loadData();
    },
    /**
     * @override
     * 加载数据方法，用到该mixin的都应该重写该方法，否则无法实现加载数据
     */
    loadData() {
      // 每个列表自己的获取数据的方法需要重写
    },
    currentChange(val) {
      this.loading = true;
      this.pageNum = val;
      this.loadData();
    }
  }
};
